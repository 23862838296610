import { Injectable } from '@angular/core'
import { AppConfig } from '../../environments/environment'

import {
	LiveMenuSettingsStyle,
	LiveMenuSettingsSettings,
	LiveMenuSettingsOptionals,
} from '@app/_interfaces/live-menu-settings-enums.interface'


export interface LiveMenu {
	loading?: boolean

	_id: string
	venue: string
	pro: boolean
	settings: LiveMenuSettingsSettings
	optionals?: LiveMenuSettingsOptionals
	style?: LiveMenuSettingsStyle
}

@Injectable({
	providedIn: 'root',
})
export class LiveMenuSettingsService {
	constructor() { }

	getLiveMenuSettings(venueId): Promise<LiveMenu> {
		return fetch(`${AppConfig.customersApiUrl}/livemenu/settings/getLiveMenuSettingsByVenueId/${venueId}`, {
			headers: {
				Accept: '*/*',
			},
		})
			.then(response => {
				return response.json()
			})
			.catch(error => {
				console.error(error)
			})
	}
}
