import { Component, ContentChild, HostBinding, Input, OnInit } from '@angular/core'
import { FormControlName } from '@angular/forms'
import { InputRefDirective } from '@app/shared/_directives/inputref/inputRef.directive'

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
	@Input() label: string
	@Input() for: string

	constructor() {}

	ngOnInit(): void {}

	@ContentChild(FormControlName)
	controlName: FormControlName

	@ContentChild(InputRefDirective)
	input: InputRefDirective

	@HostBinding('class.focus')
	get focus() {
		return this.input ? this.input.focus : false
	}

	@HostBinding('class.error')
	get error() {
		return this.controlName?.invalid && this.controlName?.dirty
	}
}
