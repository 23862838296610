export const AppConfig = {
	production: false,
	environment: 'WEB',

	// LOCAL
	// wsUrl: 'http://localhost:3003/order',
	// dineinUrl: 'http://localhost:3003/dine-in/menu/',
	// customersApiUrl: 'http://localhost:3003',
	// orderManagerApiUrl: 'http://localhost:3100/',
	// liveMenuURL: 'http://localhost:4200/',
	// waiterURL: 'http://localhost:4201/',

	// DEV
	wsUrl: 'https://customersapi-dev.azurewebsites.net/order',
	dineinUrl: 'https://live-menu-tagme-dev.netlify.app/menu/',
	customersApiUrl: 'https://customersapi-dev.azurewebsites.net',
	orderManagerApiUrl: 'https://ordermanager-dev.azurewebsites.net/',
	liveMenuURL: 'https://live-menu-dev.tagme.com.br/',
	waiterURL: 'https://waiter-dev.tagme.com.br/',

	tagmeAdminApiUrl: 'https://tagmeapi-dev.azurewebsites.net/',
	deliveryUrl: 'https://reservationwidget-dev.azurewebsites.net/delivery/',
}
