import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core'

@Component({
	selector: 'app-accordion',
	host: {
		class: 'tg-accordion',
	},
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
	@Input() label: string

	@ViewChild('toggle', { static: true }) toggle: ElementRef<HTMLCanvasElement>
	@ViewChild('content', { static: true }) content: ElementRef<HTMLCanvasElement>
	@ViewChild('contentInner', { static: true }) contentInner: ElementRef<HTMLCanvasElement>

	@HostBinding('class.tg-accordion--open') @Input() open: boolean = false

	constructor() {}

	ngOnInit() {}

	ngAfterViewInit() {
		// this.content.nativeElement.style.display = 'none'
	}

	@HostListener('click', ['$event.target'])
	onUnSelect(element) {
		if (element === this.toggle.nativeElement) this.toggleCollapse()
	}

	toggleCollapse() {
		// const toggle = this.content.nativeElement
		// const content = this.content.nativeElement
		// const contentInner = this.contentInner.nativeElement

		this.open = !this.open

		// if (this.open) {
		//   content.style.display = 'block'
		//   content.style.height = `${contentInner.offsetHeight}px`
		// } else {
		//   content.style.height = '0'
		//   setTimeout(() => content.style.display = 'none', 250);
		// }
	}
}
