import { Component, OnInit, ElementRef } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { first } from 'rxjs/operators'

import { AuthenticationService } from '../_services'
import { HttpErrorResponse } from '@angular/common/http'
import { LoaderService } from '@app/_services/loader.service'
import { Logger } from '@app/_services/logger.service'
@Component({
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	loading = false
	submitted = false
	error: HttpErrorResponse | any
	loginForm = this.fb.group({
		username: ['', Validators.required],
		password: ['', Validators.required],
	})
	bodyEl = this.elementRef.nativeElement.ownerDocument.body
	returnUrl: any
	queryParams: Params
	loginClassName = 'login-page'

	constructor(
		private fb: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private elementRef: ElementRef,
		private _loaderService: LoaderService,
		private logger: Logger
	) {
		;[this.returnUrl] = (this.route.snapshot.queryParams['returnUrl'] || '/').split('?')
		this.queryParams = this.router.parseUrl(this.returnUrl).queryParams

		// redirect to home if already logged in
		if (this.authenticationService.currentUserValue) {
			this.router.navigate(['/'])
		}
	}

	ngOnInit() {
		this.bodyEl.classList.add(this.loginClassName)
	}

	ngOnDestroy() {
		this.bodyEl.classList.remove(this.loginClassName)
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.loginForm.controls
	}

	onSubmit() {
		for (const i in this.loginForm.controls) {
			this.loginForm.controls[i].markAsDirty()
			this.loginForm.controls[i].updateValueAndValidity()
		}

		if (this.loginForm.invalid) {
			return
		}

		this.submitted = true
		this.loading = true
		this._loaderService.start()

		this.authenticationService
			.login(this.f.username.value, this.f.password.value)
			.pipe(first())
			.subscribe({
				next: () => {
					this.router.navigate([this.returnUrl], this.queryParams)
				},
				error: (xhr: HttpErrorResponse) => {
					this.logger.log(xhr)

					if (xhr?.error?.statusCode) {
						this.error = xhr.error
					} else {
						this.error = {
							statusCode: '',
							message: 'Houve um problema. Verifique sua conexão e tente novamente mais tarde.',
						}
					}

					this.loading = false
					this._loaderService.stop()
				},
				complete: () => {
					this._loaderService.stop()
				},
			})
	}
}
