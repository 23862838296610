import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core'

import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

@Injectable()
export class ModalService {
	private modal: NgbModal

	constructor(@Inject(PLATFORM_ID) private platformId: Object, private injector: Injector) {
		if (isPlatformBrowser(this.platformId)) {
			this.modal = this.injector.get(NgbModal)
		}
	}

	open(content: any, options?: NgbModalOptions): NgbModalRef {
		return this.modal.open(content, options)
	}
}
