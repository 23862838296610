import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LoginComponent } from './login.component'
import { LoginRoutingModule } from './login-routing.module'

import { ButtonModule } from '@tagmedev/tagme-components'
import { AutofocusDirective } from '@app/shared/_directives/autofocus/autofocus.directive'
import { InputModule } from '@app/shared/input.module'

@NgModule({
	declarations: [LoginComponent, AutofocusDirective],
	imports: [CommonModule, LoginRoutingModule, FormsModule, ReactiveFormsModule, InputModule, ButtonModule],
})
export class LoginModule {}
