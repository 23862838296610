import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { AuthenticationService } from '@app/_services'
import { User } from '@app/_interfaces'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	currentUser: User

	constructor(private router: Router, private authenticationService: AuthenticationService) {
		this.authenticationService.currentUser.subscribe(x => (this.currentUser = x))
	}

	ngOnInit() {}

	logout() {
		this.authenticationService.logout()
		this.router.navigate(['/login'])
	}
}
