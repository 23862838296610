import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { NotFoundComponent } from './not-found/not-found.component'

const routes: Routes = [
	{
		path: '',
		redirectTo: 'waiter',
		pathMatch: 'full',
	},
	{
		path: 'oms',
		loadChildren: () => import('./order-manager/order-manager.module').then(m => m.OrderManagerModule),
	},
	{
		path: 'admin',
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
	},
	{
		path: 'display',
		loadChildren: () => import('./order-manager/display/display.module').then(m => m.DisplayModule),
	},
	{
		path: 'waiter',
		loadChildren: () => import('./waiter/waiter.module').then(m => m.WaiterModule),
	},
	{
		path: '**',
		component: NotFoundComponent,
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
