export const users = [
	{
		id: 1,
		username: 'tagme',
		password: 'tagme',
		name: 'Tagme',
		lastName: 'Food Solutions',
		role: 'Admin',
		venueId: '56c776ff0896b3cd13c6012b',
		venueName: 'Bistrô WineTag',
	},
	{
		id: 2,
		username: 'flavio',
		password: 'flavio',
		name: 'Flávio',
		lastName: 'Sil',
		role: 'User',
		venueId: '5fa1a5bf63687c2f4c8c3d8a',
		venueName: 'Flávio Bistrô',
	},
	{
		id: 3,
		username: 'tony',
		password: 'tony',
		name: 'Antonio',
		lastName: 'Gouvea',
		role: 'User',
		venueId: '5fa1a5bf63687c2f4c8c3d8a',
		venueName: 'Tony Bistrô',
	},
]
