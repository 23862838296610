import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'

import { AppConfig } from '@env/environment'
import { AuthenticationService } from '../_services'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AuthenticationService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add auth header with jwt if user is logged in and request is to the api url
		const currentUser = this.authenticationService.currentUserValue
		const isLoggedIn = currentUser && currentUser.accessToken
		const isApiUrl = request.url.startsWith(AppConfig.customersApiUrl)
		if (isLoggedIn && isApiUrl) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${currentUser.accessToken}`,
					"Access-Control-Allow-Origin": "*"
				},
			})
		}

		return next.handle(request)
	}
}
