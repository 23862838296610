import { AppConfig } from './../../environments/environment.web'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { first, filter, catchError, publishReplay, refCount } from 'rxjs/operators'

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
	public readonly store: Record<string, Observable<HttpEvent<any>>> = {}

	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const { url, method } = req

		// Don't cache if it's not cacheable
		if (!url.includes(`${AppConfig.customersApiUrl}`) || method !== 'GET') {
			return next.handle(req)
		}

		// Check if observable is in cache, otherwise call next.handle
		const cachedObservable =
			this.store[req.urlWithParams] ||
			(this.store[req.urlWithParams] = next.handle(req).pipe(
				// Filter since we are interested in caching the response only, not progress events
				filter(res => res instanceof HttpResponse),
				// Share replay will cache the response
				// shareReplay(1)
				publishReplay(1, 60000),
				refCount()
			))

		// pipe first() to cause the observable to complete after it emits the response
		// This mimics the behaviour of Observables returned by Angular's httpClient.get()
		// And also makes toPromise work since toPromise will wait until the observable completes.
		return cachedObservable.pipe(
			first(),

			// Clear the cache in case of error and throw the error for ErrorInterceptor
			catchError(error => {
				this.store[req.urlWithParams] = null
				return throwError(error)
			})
		)
	}
}
