import { Injectable } from '@angular/core'

import { ElectronService } from '@app/core/services'

@Injectable({
	providedIn: 'root',
})
export class ElectronRingtoneService {
	playing: boolean = false
	isElectron: boolean = false

	private _loop: boolean = false
	private _sound: any = undefined
	private _INTERVAL_IN_MILLISECONDS = 3000
	private _interval: NodeJS.Timeout = undefined

	constructor(private _electronService: ElectronService) {
		this.isElectron = this._electronService.isElectron

		if (this.isElectron) {
			this._sound = require('sound-play')
		}
	}

	sound(): void {
		if (this._sound) {
			this._sound.play(`${__dirname}/assets/sounds/notify.wav`)
		}
	}

	play(): void {
		this.playing = true

		if (this._loop) {
			this._interval = setInterval(() => {
				this.sound()
			}, this._INTERVAL_IN_MILLISECONDS)
		} else {
			this.sound()
		}
	}

	pause(): void {
		this.playing = false

		if (this._loop) {
			clearInterval(this._interval)
		}
	}

	loop(): void {
		this._loop = true
	}
}
