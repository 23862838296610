import { ToastrService } from 'ngx-toastr'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core'
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper'
import { FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropEntry } from 'ngx-file-drop'
// import { TgToast } from './../toast/toast';

@Component({
	selector: 'app-image-upload',
	templateUrl: './image-upload.component.html',
	styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
	@Input() name: string
	@Input() value: string
	@Input() width = 400
	@Input() height = 400
	@Input() aspectRatio = 1
	@Input() containWithinAspectRatio = false
	@Input() cropContainerWidth = 'auto'
	@Input() cropContainerHeight = 'auto'
	@Input() showRemoveButton: boolean = false
	@Input() multiple: boolean = true

	@Output() valueEmitter = new EventEmitter<string>(true)

	// TODO: maybe move this to a 'consts' file?
	private blobUrl = 'https://tagmepub.blob.core.windows.net/pubimg/thumbs/'
	public croppedImage: any = ''
	public dropZoneLabel = ''
	public files: NgxFileDropEntry[] = []
	public imageChangedEvent: any = ''
	public scale = 1
	public showCropper = false
	public transform: ImageTransform = {}
	// TODO: maybe move this to a 'consts' file?
	public limitFileSize = 8192000
	// TODO: maybe move this to a 'consts' file?
	private labels = {
		dropImageHere: 'Arraste e solte a foto aqui',
		errorOpenImage: 'Houve um problema ao abrir a imagem. Tente salvar em seu computador e enviar novamente.',
		errorFileSize: `Esse arquivo é muito grande! Envie imagens com no máximo 8MB`,
		errorLoadImage: `Houve um problema ao carregar a imagem`,
		confirmRemoveImage: 'Tem certeza que deseja remover essa imagem?',
	}

	@ViewChild('modal', { static: true })
	public modal: TemplateRef<any>
	// public modalRef: BsModalRef;

	constructor(
		private modalService: NgbModal,
		private cdRef: ChangeDetectorRef,
		private toast: ToastrService
	) {}

	ngOnInit() {
		// this.dropZoneLabel = this.width > 120 ? this.labels.dropImageHere : " ";
		this.dropZoneLabel = this.labels.dropImageHere

		if (this.value) {
			this.croppedImage = this.fullImageUrl(this.value)
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		const valueChanges = changes['value'].currentValue
		this.croppedImage = valueChanges ? this.fullImageUrl(valueChanges) : ''
	}

	private fullImageUrl(path: String) {
		return `${this.blobUrl}${path}`
	}

	private isFileSizeAllowed(size): boolean {
		let isFileSizeAllowed = false

		if (size < this.limitFileSize) {
			isFileSizeAllowed = true
		} else {
			this.toast.error(this.labels.errorFileSize)
		}

		return isFileSizeAllowed
	}

	public dropped(event: NgxFileDropEntry[]): void {
		this.files = event

		for (const droppedFile of event) {
			// VERIFY IF IT IS A FILE
			if (droppedFile.fileEntry.isFile && droppedFile.relativePath) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry
				fileEntry.file((file: File) => {
					if (this.isFileSizeAllowed(file.size)) {
						this.fileChangeEvent({
							target: {
								files: [file],
							},
						})
					}
				})
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry

				if (!fileEntry.name) {
					this.toast.error(this.labels.errorOpenImage)

					this.closeModal()

					break
				}
			}

			if (!this.multiple) break
		}
	}

	public removeImage(): void {
		if (confirm(this.labels.confirmRemoveImage)) {
			this.croppedImage = ''
			this.emitValue()
		}
	}

	public clearInputFile($event): void {
		$event.target.value = ''
	}

	public fileUpload(event: any): void {
		const fileSize = event.target.files[0].size

		if (this.isFileSizeAllowed(fileSize)) {
			this.fileChangeEvent(event)
		}
	}

	public emitValue(): void {
		this.value = this.croppedImage
		this.valueEmitter.emit(this.croppedImage)
	}

	public fileChangeEvent(event: any): void {
		this.imageChangedEvent = event
		this.openModal()
	}

	public imageCropped(event: ImageCroppedEvent): void {
		this.croppedImage = event.base64
		this.cdRef.detectChanges()
	}

	public imageLoaded(): void {
		this.showCropper = true
	}

	public loadImageFailed(): void {
		this.toast.error(this.labels.errorLoadImage)

		this.closeModal()
	}

	public zoom(event): void {
		const nextScale = 1 + Number(event.target.value) / 10

		if (this.scale !== nextScale) {
			this.scale = nextScale
			this.transform = {
				...this.transform,
				scale: this.scale,
			}
		}
	}

	public saveImage(): void {
		this.emitValue()
		this.closeModal()
	}

	public cropContainerInPx(containerSize: string): string {
		if (containerSize !== 'auto') {
			return containerSize + 'px'
		}

		return containerSize
	}

	// MODAL
	private openModal() {
		this.modalService.open(this.modal)
	}

	public closeModal(): void {
		this.modalService.dismissAll()
	}
}
