import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { AppConfig } from '@env/environment'
import { User } from '../_interfaces'

@Injectable({ providedIn: 'root' })
export class UserService {
	constructor(private http: HttpClient) {}

	getAll() {
		return this.http.get<User[]>(`${AppConfig.customersApiUrl}/users`)
	}

	getCurrentUser(): User {
		return JSON.parse(localStorage.getItem('currentUser'))
	}

	getCurrentToken(): string {
		return this.getCurrentUser().accessToken
	}

	isAdmin(user: User) {
		return user.role === 'Admin'
	}
}
