import { Component, ElementRef, OnDestroy, AfterViewInit, ViewChild } from '@angular/core'

import { Subscription } from 'rxjs'

import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { OrderModalComponent } from '../order-modal/order-modal.component'

import { ModalService } from '@app/_services/modal.service'
import { RingtoneService } from '@app/_services/ringtone.service'
import { TableOrderService } from '@app/_services/tableOrder.service'
import { AuthenticationService } from '@app/_services/authentication.service'
import { LiveMenuSettingsService } from '@app/_services/live-menu-settings.service'

import { RingtoneTypes } from '@app/_interfaces/live-menu-settings-enums.interface'
import { ElectronRingtoneService } from '@app/_services/electron-ringtone.service'

@Component({
	selector: 'app-ringtone',
	templateUrl: './ringtone.component.html',
	styleUrls: ['./ringtone.component.scss'],
})
export class RingtoneComponent implements OnDestroy, AfterViewInit {
	venueId = JSON.parse(localStorage.getItem('currentUser'))?.venueId ?? null

	modal: NgbModalRef

	ringtoneType: RingtoneTypes
	ringtone$: Subscription

	@ViewChild('ringtoneElementRef', { static: true })
	ringtoneElementRef: ElementRef<HTMLAudioElement>
	ringtoneNativeElement: HTMLAudioElement

	hasOrdersReceived = false

	constructor(
		private modalService: ModalService,
		private ringtoneService: RingtoneService,
		private tableOrderService: TableOrderService,
		private authenticationService: AuthenticationService,
		private liveMenuSettingsService: LiveMenuSettingsService,
		private electronRingtoneService: ElectronRingtoneService
	) {}

	ngAfterViewInit() {
		this.authenticationService.currentUser.subscribe(user => {
			if (user) {
				this.venueId = user.venueId

				this.liveMenuSettingsService.getLiveMenuSettings(this.venueId).then(res => {
					this.ringtoneType = res?.settings?.notifications?.ringtone?.type ?? RingtoneTypes.ONCE

					this.ringtoneHandler()
					this.getActiveOrder()
				})
			} else {
				this.ringtoneStop()
			}
		})
	}

	ngOnDestroy() {
		this.ringtone$.unsubscribe()
	}

	ringtoneHandler() {
		if (this.electronRingtoneService.isElectron && this.ringtoneType === RingtoneTypes.LOOP) {
			this.electronRingtoneService.loop()
		} else {
			this.ringtoneNativeElement = this.ringtoneElementRef.nativeElement
			this.ringtoneNativeElement.loop = this.ringtoneType === RingtoneTypes.LOOP
			this.ringtoneNativeElement.load()
		}

		this.ringtone$ = this.ringtoneService.shouldByPlaying$.subscribe(should => {
			should ? this.ringtonePlay() : this.ringtoneStop()
		})
	}

	async ringtonePlay() {
		if (this.electronRingtoneService.isElectron) {
			this.electronRingtoneService.play()
			return
		}

		if (this.ringtoneNativeElement && this.ringtoneNativeElement.paused) {
			await this.ringtoneNativeElement.play().catch(() => {
				this.openModal()
			})
		}
	}

	ringtoneStop() {
		if (this.electronRingtoneService.isElectron) {
			this.electronRingtoneService.pause()
			return
		}

		if (this.ringtoneNativeElement && !this.ringtoneNativeElement.paused) {
			this.ringtoneNativeElement.pause()
		}
	}

	getActiveOrder() {
		this.tableOrderService.getAllTableOrders(this.venueId).subscribe(
			res => {
				this.hasOrdersReceived = res.find(tableOrder =>
					tableOrder.orders.find(order => order.status === 'Received') ? true : false
				)
					? true
					: false

				if (this.hasOrdersReceived) {
					this.ringtoneService.dispatchRingtone()
					return
				}

				// FORCE INTERACTION WITH PAGE
				this.openModal()
			},
			err => {
				console.error(err)
			}
		)
	}

	openModal() {
		const modal: NgbModalRef = this.modalService.open(OrderModalComponent, {
			backdrop: 'static',
			keyboard: false,
		})

		modal.componentInstance.modalType = 'interactWithPage'

		modal.componentInstance.clickevent.subscribe(() => {
			if (this.hasOrdersReceived) {
				this.ringtoneNativeElement.play()
			}

			modal.close()
		})
	}
}
