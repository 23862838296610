import { Component, Input, OnInit, TemplateRef } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
	@Input() title: String
	@Input() content: any
	@Input() closeButton = true

	template: TemplateRef<any>

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}
}
