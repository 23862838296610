import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'

@Component({
	selector: 'app-nav-tabs',
	templateUrl: './nav-tabs.component.html',
	styleUrls: ['./nav-tabs.component.scss'],
})
export class NavTabsComponent implements OnInit {
	@Input() navLinks: any[]
	@Input() isMobile = false
	@Input() type: string
	activeLinkIndex = -1

	@Output() clickEmitter = new EventEmitter()

	constructor(private router: Router) {}

	ngOnInit() {
		this.router.events.subscribe(() => {
			this.activeLinkIndex = this.navLinks.indexOf(
				this.navLinks.find(tab => tab.link === '.' + this.router.url)
			)
		})
	}
}
