import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit {
	@Input() text = ''
	@Input() type = ''
	@Input() rounded = false
	@Input() outline = false
	@Input() arrow = ''
	@Input() customStyles: string

	typeClass = ''
	tagClass = 'tag'
	outlineClass = '-outline-'
	arrowClass = '-arrow-'

	constructor() {}

	ngOnInit(): void {
		let classToBeAdded = ''

		if (this.type) {
			classToBeAdded = ` ${this.tagClass}-${this.type}`
		}
		if (this.outline) {
			classToBeAdded = ` ${this.tagClass}${this.outlineClass}${this.type}`
		}
		if (this.arrow) {
			classToBeAdded += ` ${this.tagClass}${this.arrowClass}${this.arrow}`
		}

		this.addToTypeClass(classToBeAdded)
	}

	addToTypeClass(className: string): string {
		this.typeClass = className
		return this.typeClass
	}
}
