import localePt from '@angular/common/locales/pt'
import { BrowserModule } from '@angular/platform-browser'
import { ServiceWorkerModule } from '@angular/service-worker'
import { NgOptimizedImage, registerLocaleData } from '@angular/common'
import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE } from '@angular/core'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http'
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

// Translation Module
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'

import { ToastrModule } from 'ngx-toastr'
import { StoreModule } from '@ngrx/store'

import { CoreModule } from './core/core.module'
import { LoginModule } from './login/login.module'
import { AppRoutingModule } from './app-routing.module'
import { NotFoundModule } from './not-found/not-found.module'
import { SwUpdatesModule } from './_services/sw-updates/sw-updates.module'

// Loading overlay service
import { LoadingModule } from '@app/shared/loading.module'

import { ModalService } from './_services/modal.service'
import { UtilsService } from './_services/utils.service'
import { LoaderService } from './_services/loader.service'

// Used to create fake backend
import { fakeBackendProvider, JwtInterceptor } from './_helpers'

// Fake auth
// import { JwtInterceptor } from './_helpers';
import { ErrorInterceptor } from './_helpers'

import { AppComponent } from './app.component'
import { NgxMaskModule } from 'ngx-mask'
import { RingtoneModule } from './waiter/_components/ringtone/ringtone.module'

export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
	return new TranslateHttpLoader(new HttpClient(httpBackend))
}

registerLocaleData(localePt, 'pt')
@NgModule({
	declarations: [AppComponent],
	imports: [
		RingtoneModule,
		BrowserModule,
		BrowserAnimationsModule,
		NoopAnimationsModule,
		HttpClientModule,
		CoreModule,
		NotFoundModule,
		NgOptimizedImage,
		LoginModule,
		LoadingModule,
		AppRoutingModule,
		TranslateModule.forRoot({
			defaultLanguage: 'pt-br',
			loader: {
				provide: TranslateLoader,
				useFactory: translateHttpLoaderFactory,
				deps: [HttpBackend],
			},
		}),
		ServiceWorkerModule.register('sw.js', {
			enabled: true,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerImmediately',
			scope: '/',
		}),
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
		}),
		SwUpdatesModule,
		StoreModule.forRoot({}, {}),
		NgxMaskModule.forRoot(),
	],
	providers: [
		NgbActiveModal,
		ModalService,
		UtilsService,
		LoaderService,
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },

		// provider used to create fake backend
		fakeBackendProvider,

		// provider used to create fake auth
		// { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

		// provider used to create a cache (service worker will handle it)
		// { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },

		// provider used to handle response errors
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
	exports: [LoadingModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
