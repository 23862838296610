export const deliveryApps = [
	{
		id: 1,
		name: 'iFood',
		slug: 'ifood',
		integrationInfo: `<p>
    Sigo o passo a passo do vídeo para realizar a ativação
    <br/><br/>
    É rapidinho, leva só 2 minutos. 
  </p>`,
		imageUrl: '../../../../assets/marketplaces/ifood.svg',
		title: 'Onde está o código iFood',
		content: `<div class="text-center"><svg width="315" height="193" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#30323B" d="M0 0h315v193H0z"/>
    <path d="M144.55 96.119l-.328-17.596c-.045-2.38 2.566-3.86 4.585-2.603l28.342 17.652c1.885 1.175 1.885 3.92 0 5.093l-28.342 17.652c-2.019 1.258-4.63-.223-4.585-2.602l.328-17.596z" fill="#FF7F40"/>
    <circle cx="158" cy="96" r="48" stroke="#FF7F40" stroke-width="4"/>
  </svg></div>`,
	},
	{
		id: 2,
		name: 'Uber Eats',
		slug: 'ubereats',
		integrationInfo: `<p>
    Digite seu código de segurança e ative a gestão de seus pedidos Uber Eats em um só lugar.
    <br/><br/>
    É rapidinho, leva só 2 minutos. 
  </p>`,
		imageUrl: '../../../../assets/marketplaces/ubereats.svg',
		title: 'Onde está o código Uber Eats',
		content: `<div class="text-center"><svg width="315" height="193" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#30323B" d="M0 0h315v193H0z"/>
    <path d="M144.55 96.119l-.328-17.596c-.045-2.38 2.566-3.86 4.585-2.603l28.342 17.652c1.885 1.175 1.885 3.92 0 5.093l-28.342 17.652c-2.019 1.258-4.63-.223-4.585-2.602l.328-17.596z" fill="#FF7F40"/>
    <circle cx="158" cy="96" r="48" stroke="#FF7F40" stroke-width="4"/>
  </svg></div>`,
	},
	// {
	//   id: 3,
	//   name: "Rappi",
	//   integrationInfo: `<p>
	//   Digite seu código de segurança e ative a gestão de seus pedidos Rappi em um só lugar.
	//   <br/><br/>
	//   É rapidinho, leva só 2 minutos.
	// </p>`,
	//   imageUrl: "../../../../assets/marketplaces/rappi.svg",
	//   title: "Onde está o código Rappi",
	//   content: `
	//   <p>Rappi custom content</p> <hr/>
	//   <div class="py-4">
	//     <tg-button text="Rappi"></tg-button>
	//   </div>
	//   <ul class="text-left">
	//     <li>passo 1</li>
	//     <li>passo 2</li>
	//     <li>passo 3</li>
	//     <li>passo 4</li>
	//   </ul>
	// `,
	// },
]
