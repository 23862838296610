import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
	@Input() percentage: number = 0
	@Input() type: String = ''
	@Input() animate = false

	progressStyle: string = ''
	constructor() {}

	ngOnInit() {}

	getStyle() {
		return { width: `${this.percentage}%` }
	}

	getClass() {
		let className = ''

		className += this.type ? ` progress-bar-${this.type}` : ''
		className += this.animate ? ` progress-bar-animated` : ''

		return className
	}
}
