import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
	@Input() text: String = ''
	@Input() alt: String = ''
	@Input() type: String = ''

	typeClass = ''
	tagClass = 'tg-notification'

	constructor() {}

	ngOnInit(): void {
		this.addToTypeClass(` ${this.tagClass}-${this.type}`)
	}

	addToTypeClass(className: String): String {
		this.typeClass = this.typeClass + className
		return this.typeClass
	}
}
