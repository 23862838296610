import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class LoaderService {
	private status = new BehaviorSubject<boolean>(false)
	public status$ = this.status.asObservable()

	public get active(): boolean {
		return this.status.getValue()
	}

	public set active(v: boolean) {
		this.status.next(v)
	}

	public start(): void {
		this.status.next(true)
	}

	public stop(): void {
		this.status.next(false)
	}
}
