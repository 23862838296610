import { Injectable } from '@angular/core'

import { BehaviorSubject } from 'rxjs'

import { ORDER_NOTIFICATION_ACTIONS } from '@app/_interfaces'
import { RingtoneTypes } from '@app/_interfaces/live-menu-settings-enums.interface'

import { TableOrderService } from './tableOrder.service'
import { AuthenticationService } from './authentication.service'
import { LiveMenuSettingsService } from './live-menu-settings.service'

@Injectable({
	providedIn: 'root',
})
export class RingtoneService {
	private shouldBePlaying = new BehaviorSubject<boolean>(false)
	shouldByPlaying$ = this.shouldBePlaying.asObservable()

	private venueId: string;

	ringtoneType: RingtoneTypes

	constructor(
		private tableOrderService: TableOrderService,
		private authenticationService: AuthenticationService,
		private liveMenuSettingsService: LiveMenuSettingsService
	) {
		this.authenticationService.currentUser.subscribe(user => {
			if (user) {
				this.venueId = user.venueId

				this.liveMenuSettingsService.getLiveMenuSettings(this.venueId).then(res => {
					this.ringtoneType = res?.settings?.notifications?.ringtone?.type ?? RingtoneTypes.ONCE
				})
			}
		})
	}

	play(): void {
		if (this.shouldBePlaying.value) {
			return
		}

		this.shouldBePlaying.next(true)
	}

	stop(): void {
		if (!this.shouldBePlaying.value) {
			return
		}

		this.shouldBePlaying.next(false)
	}

	dispatchRingtone(action: string = ORDER_NOTIFICATION_ACTIONS.Receive): void {
		if (this.ringtoneType === RingtoneTypes.ONCE) {
			if (action !== ORDER_NOTIFICATION_ACTIONS.Receive) {
					return
			}
		this.stop()
	}

		this.tableOrderService.getAllTableOrders(this.venueId).subscribe((tables) => {
			for (const table of tables) {
				if (table.orders.some(order => order.status === 'Received')) {
					this.checkTypeToStartRingtone()
					return
				}
			}

			this.stop()
		})
	}

	private checkTypeToStartRingtone(): void {
		switch (this.ringtoneType) {
			case RingtoneTypes.DISABLED:
				break

			case RingtoneTypes.ONCE:
			case RingtoneTypes.LOOP:
			default:
				this.play()
				break
		}
	}
}
