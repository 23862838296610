import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-input-copy',
	templateUrl: './input-copy.component.html',
	styleUrls: ['./input-copy.component.scss'],
})
export class InputCopyComponent implements OnInit {
	@Input() text: any
	@Output() onSuccess: EventEmitter<any> = new EventEmitter()

	isCopied = false

	constructor() {}

	ngOnInit(): void {}

	cbOnSuccess() {
		this.isCopied = true

		setTimeout(() => {
			this.isCopied = false
		}, 700)

		this.onSuccess.emit()
	}
}
