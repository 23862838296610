import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { AppConfig } from '@env/environment'
import { User } from '../_interfaces'
import { Logger } from './logger.service'

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User>
	public currentUser: Observable<User>

	constructor(private http: HttpClient, private logger: Logger) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')))
		this.currentUser = this.currentUserSubject.asObservable()
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value
	}

	login(username: string, password: string) {
		return this.http
			.post<any>(`${AppConfig.customersApiUrl}/auth/login`, {
				username,
				password,
			})
			.pipe(
				map(user => {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					localStorage.setItem('currentUser', JSON.stringify(user))
					this.currentUserSubject.next(user)
					return user
				})
			)
	}

	refreshToken() {
		// TODO: Create a watcher for localStorage updates to catch authToken/user changes and revalidate/reload page.
		const currentUser = this.currentUserValue

		this.http
			.get(`${AppConfig.customersApiUrl}/auth/refresh`, {
				headers: {
					accept: 'application/json',
					Authorization: `Bearer ${currentUser.accessToken}`,
				},
			})
			.pipe(take(1))
			.subscribe(
				next => {
					this.logger.log(`✅ refreshToken success -- update token`)
					this.logger.log(next)
				},
				error => {
					this.logger.log(`⛔️ refreshToken fail -- logout user ${error}`)
					this.logout()
					location.reload()
				}
			)
	}

	logout() {
		// remove user from local storage to log user out
		localStorage.removeItem('currentUser')
		this.currentUserSubject.next(null)
	}
}
