import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
	urlPath: String

	@Output() clickEmmiter = new EventEmitter()

	constructor(private router: Router) {}

	ngOnInit(): void {
		this.urlPath = this.router.url
	}

	goHome(): any {
		this.clickEmmiter.emit('clicked')
		this.router.navigateByUrl('/oms')
	}
}
