import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-receipt-paper',
	templateUrl: './receipt-paper.component.html',
	styleUrls: ['./receipt-paper.component.scss'],
})
export class ReceiptPaperComponent implements OnInit {
	randomNumber = Math.floor(Math.random() * 100)
	maskA = `url(#paperA${this.randomNumber})`
	maskB = `url(#paperB${this.randomNumber})`
	maskC = `url(#paperC${this.randomNumber})`

	constructor() {}

	ngOnInit() {}
}
