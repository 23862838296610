import { Component, OnInit } from '@angular/core'

import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core'

import { AppConfig } from '@env/environment'

import { ElectronService } from './core/services'

import { Logger } from '@app/_services/logger.service'
import { DeviceService } from '@app/_services/device.service'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	constructor(
		private logger: Logger,
		private toastr: ToastrService,
		private translate: TranslateService,
		private deviceService: DeviceService,
		private electronService: ElectronService
	) {
		this.translate.setDefaultLang('pt-br')

		this.logger.log('AppConfig', AppConfig)

		if (electronService.isElectron) {
			this.logger.log(process.env)
			this.logger.log('Run in electron')
			this.logger.log('Electron ipcRenderer', this.electronService.ipcRenderer)
			this.logger.log('NodeJS childProcess', this.electronService.childProcess)
		} else {
			this.logger.log('Run in browser')
		}
	}

	ngOnInit() {
		this.deviceService.createOnline$().subscribe(isOnline => {
			if (!isOnline) {
				this.toastr.warning('Informações disponíveis apenas para consulta', 'Você está offline', {
					timeOut: 0,
					extendedTimeOut: 0,
				})
			} else {
				this.toastr.clear()
			}
		})
	}
}
