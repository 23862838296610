import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { AppConfig } from '../../environments/environment'

import { IWaiter } from '@app/_interfaces/waiter.interface'
import { DefaultResponse } from '@app/_interfaces/default-response.interface'
import { Observable } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class TableOrderService {
	constructor(private _http: HttpClient) { }

	public getHeaders(): HttpHeaders {
		return new HttpHeaders({
			Authorization: `Bearer ${JSON.parse(localStorage.currentUser).accessToken}`,
		})
	}

	public getTableOrderById(tableOrder: string): any {
		return this._http.get<any>(`${AppConfig.customersApiUrl}/livemenu/order/${tableOrder}`)
	}

	public getTableOrderByIdWithDetails(tableOrderId: string): any {
		return this._http.get<any>(`${AppConfig.customersApiUrl}/livemenu/order/${tableOrderId}/details`)
	}

	public getOneTableOrder(venueId: string, tableNumber: string): any {
		return this._http.get<any>(
			`${AppConfig.customersApiUrl}/livemenu/order/currentTableOrder?venue=${venueId}&table=${tableNumber}`
		)
	}

	public getAllTableOrders(venueId: string): any {
		return this._http.get<any>(
			`${AppConfig.customersApiUrl}/livemenu/order/byVenue/${venueId}/getAllTableOrders`
		)
	}

	public refuseOrder(orderId: string, reason?: string): any {
		return this._http.post<any>(`${AppConfig.customersApiUrl}/livemenu/order/${orderId}/refuse`, { reason })
	}

	public refuseOrderItem(orderId: string, itemId: string, reason?: string): any {
		return this._http.post<any>(`${AppConfig.customersApiUrl}/livemenu/order/${orderId}/${itemId}/refuse`, { reason })
	}

	public cancelOrder(orderId: string, reason?: string, passwordCancelOrder?: string): Observable<DefaultResponse> {
		return this._http.post<any>(`${AppConfig.customersApiUrl}/livemenu/order/${orderId}/cancel`, { reason, passwordCancelOrder })
	}

	public cancelOrderItem(orderId: string, itemId: string, reason?: string, passwordCancelOrder?: string): Observable<DefaultResponse> {
		return this._http.post<any>(`${AppConfig.customersApiUrl}/livemenu/order/${orderId}/${itemId}/cancel`, { reason, passwordCancelOrder })
	}

	public acceptOrderItem(orderId: string, itemId: string, waiter: IWaiter): any {
		return this._http.post<any>(
			`${AppConfig.customersApiUrl}/livemenu/order/${orderId}/${itemId}/accept`,
			waiter
		)
	}

	public alterOrderItem(orderId: string, itemId: string, waiterId: string, venueId: string): any {
		return this._http.put<any>(
			`${AppConfig.customersApiUrl}/livemenu/order/${orderId}/${itemId}/${waiterId}/${venueId}/alter`,
			{},
			{ headers: this.getHeaders() }
		)
	}

	public acceptOrder(orderId: string, waiter: { _id: string; name: string }): any {
		return this._http.put<any>(`${AppConfig.customersApiUrl}/livemenu/order/${orderId}/accept`, { waiter })
	}

	public closeTable(tableOrder: string) {
		return this._http.put<any>(
			`${AppConfig.customersApiUrl}/livemenu/order/${tableOrder}/close`,
			{},
			{ headers: this.getHeaders() }
		)
	}

	public getTableHistory(venueId: string, days: string = '20') {
		return this._http.get<any>(
			`${AppConfig.customersApiUrl}/livemenu/order/byVenue/${venueId}/${days}/history`,
			{ headers: this.getHeaders() }
		)
	}

	public confirmDeliveryOrder(waiterId: string, orderId: string, venueId: string) {
		const payload = {
			waiterId,
			orderId,
			venueId,
		}

		return this._http.put<any>(
			`${AppConfig.customersApiUrl}/livemenu/order/confirmDeliveryOrder`,
			payload,
			{ headers: this.getHeaders() }
		)
	}

	public writeOffCustomer(productUserId: string, tableOrderId: string) {
		const payload = {
			productUserId,
			tableOrderId,
		}

		return this._http.put<any>(
			`${AppConfig.customersApiUrl}/livemenu/order/writeOffCustomer`,
			payload,
			{ headers: this.getHeaders() }
		)
	}
}
