import { Directive, Input, OnChanges } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
	selector: '[appDisableControl]',
})
export class DisableControlDirective implements OnChanges {
	@Input() disableControl: boolean

	constructor(private ngControl: NgControl) {}

	ngOnChanges() {
		if (this.disableControl) {
			this.ngControl.control.disable()
		} else {
			this.ngControl.control.enable()
		}
	}
}
