import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppConfig } from '@env/environment'
import { GetPaymentHistoryResponseDto } from './dto/payment-history.dto'


export class PayloadExternalPayment {
	value: number
	tableOrderId: string
	productUser: string
	waiter: {
		_id: string,
		name: string,
	}
	saveCard: boolean
	type: string
	externalPayments?: ExternalPayments[] | null
	venue: string
}

export class ExternalPayments {
	value: number
	type: string
	brand: string
}

@Injectable({
	providedIn: 'root',
})
export class PaymentsService {
	paymentsMethods = [
		'alelo',
		'amex',
		'cash',
		'diners',
		'elo',
		'hipercard',
		'master',
		'mastercard',
		'sodexo',
		'visa',
		'vr',
	]

	constructor(private _http: HttpClient) { }

	public getHeaders(): HttpHeaders {
		return new HttpHeaders({
			Authorization: `Bearer ${JSON.parse(localStorage.currentUser).accessToken}`,
		})
	}

	pay(payload: PayloadExternalPayment, orderId: string) {
		return this._http.post<any>(
			`${AppConfig.customersApiUrl}/livemenu/order/${orderId}/pay`,
			payload,
			{ headers: this.getHeaders() }
		)
	}

	getPaymentImage(paymentMethod: string = ''): string {
		let formattedMethod = paymentMethod.trim().toLowerCase().replace(' ', '-')

		if (!this.paymentsMethods.includes(formattedMethod)) return

		return `./assets/payments/${formattedMethod}.svg`
	}

	public getPaymentHistory(
		venueId: string,
		fromDate: string,
		toDate: string,
		skip: number,
		limit: number
	): Observable<GetPaymentHistoryResponseDto> {
		return this._http.post<GetPaymentHistoryResponseDto>(
			`${AppConfig.customersApiUrl}/livemenu/order/byVenue/${venueId}/getPaymentHistory`,
			{
				fromDate: fromDate,
				toDate: toDate,
				skip: skip,
				limit: limit,
			},
			{ headers: this.getHeaders() }
		)
	}

	public cancelPayment(tableOrder: string, paymentId: string): Observable<GetPaymentHistoryResponseDto> {
		return this._http.post<GetPaymentHistoryResponseDto>(
			`${AppConfig.customersApiUrl}/livemenu/order/${tableOrder}/pay/${paymentId}/cancel`,
			{},
			{ headers: this.getHeaders() }
		)
	}
}
