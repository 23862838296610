import { Component, OnInit } from '@angular/core'

const CLOCK_UPDATE_INTERVAL = 1

@Component({
	selector: 'app-date-time',
	templateUrl: './date-time.component.html',
	styleUrls: ['./date-time.component.scss'],
})
export class DateTimeComponent implements OnInit {
	public today: Date = new Date()
	private clockHandler: any

	constructor() {}

	ngOnInit(): void {
		this.startClock()
	}

	private stopInterval(handler: any): void {
		if (!handler) return

		clearInterval(handler)
	}

	private startClock() {
		this.stopInterval(this.clockHandler)

		this.clockHandler = setInterval(() => {
			this.today = new Date()
		}, CLOCK_UPDATE_INTERVAL * 1000)
	}
}
