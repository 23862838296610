import { Component, OnInit, forwardRef, HostBinding, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
	selector: 'app-switch',
	templateUrl: './switch.component.html',
	styleUrls: ['./switch.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SwitchComponent),
			multi: true,
		},
	],
})
export class SwitchComponent implements ControlValueAccessor, OnInit {
	@HostBinding('attr.id')
	externalId = ''

	@Input()
	set id(value: string) {
		this._ID = value
		this.externalId = null
	}

	get id() {
		return this._ID
	}

	private _ID = ''

	onChange: any = () => {}
	onTouch: any = () => {}

	registerOnChange(fn: any): void {
		this.onChange = fn
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn
	}

	constructor() {}

	ngOnInit() {}

	checked: boolean = false
	writeValue(checked: boolean) {
		this.checked = checked
	}

	onModelChange(e: boolean) {
		this.checked = e
		this.onChange(e)
	}
}
