import { GetPaymentRecordDto } from './dto/payment-history.dto';
import { Injectable } from '@angular/core'
import * as Excel from 'exceljs';

@Injectable()
export class UtilsService {
	constructor() {}

	formatValueInCentsToFloat(valueInCents: string): string {
		const valueFormatted = +valueInCents / 100

		return valueFormatted.toLocaleString('pt-br', {
			minimumFractionDigits: 2,
		})
	}

	async generatePaymentHistoryBlob(paymentHistory: GetPaymentRecordDto[]) {
		const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Historico de Pagamentos');

		const headerAttr = {
			font: {size: 20}
		} 

        const countriesColumns: Partial<Excel.Column>[] = [
            { key: 'table', header: 'MESA', width: 20, ...headerAttr},
            { key: 'transactionDate', header: 'DATA TRANSAÇÃO', width: 35, ...headerAttr},
            { key: 'name', header: 'NOME', width: 20, ...headerAttr},
            { key: 'status', header: 'STATUS', width: 30, ...headerAttr},
            { key: 'payment_method', header: 'FORMA DE PAGAMENTO', width: 35, ...headerAttr},
            { key: 'netValue', header: 'VALOR LÍQUIDO', width: 30, ...headerAttr},
            { key: 'grossValue', header: 'TOTAL DO PAGAMENTO', width: 30, ...headerAttr},
        ];

		worksheet.columns = countriesColumns;

		for(const h of paymentHistory) {
        	worksheet.addRow({
				table: h.table.toString(), 
				transactionDate: h.transactionDate, 
				name: h.customerName,
				status: h.status, 
				payment_method: h.paymentMethod,
				netValue: h.netValue,
				grossValue: h.grossValue
            })
		}

		const buffer = await workbook.csv.writeBuffer()
		
		return new Blob([buffer], {type: "text/csv"});
	}
}
