import { HostListener } from '@angular/core'
import { Directive } from '@angular/core'

@Directive({
	selector: '[appInputRef]',
})
export class InputRefDirective {
	focus = false

	@HostListener('focus')
	onFocus() {
		this.focus = true
	}

	@HostListener('blur')
	onBlur() {
		this.focus = false
	}
}
