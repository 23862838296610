import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from '@app/app.module'
import { AppConfig } from '@env/environment'

if (AppConfig.production) {
	enableProdMode()
}

platformBrowserDynamic()
	.bootstrapModule(AppModule, {
		preserveWhitespaces: false,
	})
	.catch(err => console.error(err))
