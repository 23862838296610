export const AppConfig = {
	production: false,
	environment: 'DEV',

	// orderManagerApiUrl: 'http://localhost:4200/api', // proxy.config -- proxy to https://ordermanagerdev.azurewebsites.net
	orderManagerApiUrl: 'https://ordermanager-dev.azurewebsites.net',
	tagmeAdminApiUrl: 'https://tagmeapistaging.azurewebsites.net/',
	customersApiUrl: 'https://customersapi-dev.azurewebsites.net',
	wsUrl: 'https://customersapi-dev.azurewebsites.net/order',

	deliveryUrl: 'https://reservation-widget-staging.azurewebsites.net/delivery/',
	dineinUrl: 'https://live-menu-dev.tagme.com.br/menu/',
	liveMenuURL: 'https://live-menu-dev.tagme.com.br/',
	waiterURL: 'https://waiter-dev.tagme.com.br/',
	// liveMenuURL: 'https://thankful-bay-08e0f300f-336.eastus2.1.azurestaticapps.net/',
	// waiterURL: 'https://gray-ground-0f557920f-123.eastus2.1.azurestaticapps.net/',
}
