import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { NotFoundComponent } from './not-found.component'

import { ButtonModule, LinkModule } from '@tagmedev/tagme-components'
@NgModule({
	declarations: [NotFoundComponent],
	imports: [CommonModule, RouterModule, ButtonModule, LinkModule],
})
export class NotFoundModule {}
