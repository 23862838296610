import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { ToastrService } from 'ngx-toastr'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { InteractionTimer } from '@app/_interfaces/interaction-timer.interface'
import { CancelationOptions } from '@app/_interfaces/live-menu-settings-enums.interface'

import { PaymentsService, PayloadExternalPayment } from '@app/_services/payments.service'

@Component({
	selector: 'app-order-modal',
	templateUrl: './order-modal.component.html',
	styleUrls: ['./order-modal.component.scss'],
})
export class OrderModalComponent implements OnInit {
	@Input() order: any
	@Input() modalType: string
	@Input() productUser?: any
	@Input() currentOrderFromCustomer: any
	@Input() error?: string
	@Input() iframeData?: {
		src: string
		postMessage: {
			data: any
			targetOrigin: string
		}
	}
	@Input() paymentRequests?: InteractionTimer[] = []
	@Input() pdvErrors?: any[]
	@Input() cancelationOptions?: CancelationOptions

	@Output() statusChangeEmitter = new EventEmitter()
	@Output() clickevent = new EventEmitter<string>()

	@HostListener('window:message', ['$event'])
	onMessage(event) {
		this.messageListener(event)
	}

	currentUser = JSON.parse(localStorage.getItem('currentUser'))

	isLoading = false

	paymentExtra = 0

	clientList = []

	selectedProductUserId = ''
	selectedProductUser = null
	previousSelectedProductUserId = ''

	srcSanitized: SafeResourceUrl

	paymentMethods = [
		{ id: 'pix', type: 'pix', brand: null, label: 'Pix' },
		{ id: 'cash', type: 'cash', brand: null, label: 'Dinheiro' },

		{ id: 'credit_card-elo', type: 'credit_card', brand: 'Elo', label: 'Crédito - Elo' },
		{ id: 'credit_card-visa', type: 'credit_card', brand: 'Visa', label: 'Crédito - Visa' },
		{ id: 'credit_card-mastercard', type: 'credit_card', brand: 'MasterCard', label: 'Crédito - MasterCard' },

		{ id: 'debit_card-elo', type: 'debit_card', brand: 'Elo', label: 'Débito - Elo' },
		{ id: 'debit_card-visa', type: 'debit_card', brand: 'Visa', label: 'Débito - Visa' },
		{ id: 'debit_card-mastercard', type: 'debit_card', brand: 'MasterCard', label: 'Débito - MasterCard' },
	]

	paymentForm: UntypedFormGroup
	paymentStep: 'form' | 'success' = 'form'
	paymentDataSuccess = null

	selectedCookingTime = 0

	reason = ''
	passwordCancelOrder = ''
	invalidPassword = false

	productUserInfoStep: 'productUserInfo' | 'confirmRemove' = 'productUserInfo'

	constructor(
		public fb: UntypedFormBuilder,
		private modal: NgbActiveModal,
		private toastr: ToastrService,
		private sanitizer: DomSanitizer,
		private paymentsService: PaymentsService,
	) { }

	ngOnInit(): void {
		switch (this.modalType) {
			case 'newOrder':
				this.getClientList()
				break

			case 'alterOrderItem':
				this.srcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeData?.src)
				break

			case 'orderFromWaiter':
				this.getClientList()

				this.selectedProductUser = this.currentOrderFromCustomer?.productUser || null
				this.selectedProductUserId = this.iframeData.postMessage.data.productUser._id || ''
				this.previousSelectedProductUserId = this.selectedProductUserId

				this.srcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeData?.src)
				break

			case 'payment':
				this.getClientList()

				this.paymentForm = this.fb.group({
					productUserId: ['', Validators.required],
					paymentInfos: this.fb.array([])
				})

				if (this.paymentRequests.length === 1) {
					this.paymentForm.get('productUserId').setValue(this.paymentRequests[0].productUser._id)

					const paymentFromRequest = this.fb.group({
						paymentMethod: ['', Validators.required],
						value: [
							(this.paymentRequests[0].interactionData.valueToPay / 100),
							[Validators.required, Validators.min(0.01)]
						],
					})

					this.paymentInfos().push(paymentFromRequest)
				}
				else {
					this.addNewUserPaymentInfo()
				}

				break
		}

		this.paymentExtra = this.order?.remainingOrdersPrice < 0 ? Math.abs(this.order?.remainingOrdersPrice) : 0
	}

	closeModal(reason: string = 'closeModal'): void {
		this.modal.close(reason)
	}

	handleClickEvent(ev) {
		this.invalidPassword = false

		if (
			ev.type === 'cancelation' &&
			this.cancelationOptions?.needPassword &&
			(
				!this.passwordCancelOrder ||
				this.passwordCancelOrder === ''
			)
		) {
			this.invalidPassword = true
			return
		}

		this.clickevent.emit(ev)
	}

	hasOptions(selectedOptions: any[]) {
		return selectedOptions.length > 0
	}

	getSelectedOptionsTotalPrice(selectedOptions: any[]) {
		let sum = 0

		selectedOptions.forEach(option => {
			option.sons.forEach(son => {
				sum += son.quantity * (son.promoPriceEnabled ? son.promoPrice : son.price)
			})
		})

		return sum
	}

	getClientList() {
		if (!this.order) return []

		this.order.orders.filter((v, i, a) => {
			if (a.findIndex(v2 => v2.productUser._id === v.productUser._id) === i) {
				this.clientList.push(v)
			}
		})
	}

	setProductUser(clientId: string) {
		if (this.modalType === 'newOrder' || this.modalType === 'payment') {
			this.selectedProductUser = this.clientList.find(v => v.productUser._id === clientId).productUser
			return
		}

		if (confirm('Deseja alterar o cliente?')) {
			this.selectedProductUser = this.clientList.find(v => v.productUser._id === clientId).productUser

			this.handleClickEvent(this.selectedProductUser)

			return
		}

		setTimeout(() => {
			this.selectedProductUserId = this.previousSelectedProductUserId
		}, 0)
	}

	messageListener(event): void {
		if (event.data.waiterIframeLoaded) {
			; (<HTMLIFrameElement>document.querySelector('#liveMenuIframe')).contentWindow.postMessage(
				{
					data: this.iframeData?.postMessage?.data,
					waiterMode: true,
				},
				this.iframeData?.postMessage?.targetOrigin
			)
		}

		if (event.data.cartItemUpdated) {
			this.toastr.success('Item foi atualizado com sucesso', 'Item atualizado!')
			this.handleClickEvent(true)
		}
	}

	// ---------------------------------- PAYMENT ---------------------------------- //

	addNewUser() {
	}

	paymentInfos(): UntypedFormArray {
		return this.paymentForm.get("paymentInfos") as UntypedFormArray
	}

	newPaymentInfo() {
		return this.fb.group({
			paymentMethod: ['', Validators.required],
			value: [0, [Validators.required, Validators.min(0.01)]],
		})
	}

	addNewUserPaymentInfo() {
		this.paymentInfos().push(this.newPaymentInfo())
	}

	removeUserPaymentInfo(index: number) {
		this.paymentInfos().removeAt(index)
	}

	async pay() {
		this.isLoading = true

		const payload = this.adaptPaymentForm()

		await this.paymentsService.pay(payload, this.order._id).subscribe((response) => {
			this.handleClickEvent(null)

			const paymentId = response.paymentResult.id

			const lastPaymentHistory = response.paymentHistory.find(hist => hist.payment === paymentId)

			this.paymentDataSuccess = {
				productUser: response.productUser,
				payments: lastPaymentHistory.metadata.externalPayments.map(payment => {
					return {
						date: new Date(lastPaymentHistory.date),
						brand: payment.brand,
						type: payment.type,
						value: payment.value,
					}
				})
			}

			this.paymentStep = 'success'

			this.isLoading = false
		}, error => {
			this.isLoading = false

			console.error(error)

			alert('Falha no pagamento, por favor entre em contato com o administrador.')
		})
	}

	adaptPaymentForm(): PayloadExternalPayment {
		return {
			value: this.paymentForm.get("paymentInfos").value.reduce((accum, current) => {
				return accum + this.adaptValueToCent(current.value)
			}, 0),
			tableOrderId: this.order._id,
			productUser: this.paymentForm.get("productUserId").value,
			waiter: {
				_id: this.currentUser.userId,
				name: this.currentUser.name,
			},
			saveCard: false,
			type: 'external',
			externalPayments: this.paymentForm.get("paymentInfos").value.map(elem => {
				return {
					value: this.adaptValueToCent(elem.value),
					type: elem.paymentMethod.type,
					brand: elem.paymentMethod.brand,
				}
			}),
			venue: this.currentUser.venueId
		}
	}

	adaptValueToCent(value: number): number {
		return value * 100
	}

	normalizeCardType(cardType: string): string {
		let type = 'Not defined'

		switch (cardType) {
			case 'credit_card':
				type = 'Crédito'
				break

			case 'debit_card':
				type = 'Débito'
				break
		}

		return type
	}

	brandImage(brand: string): string {
		switch (brand) {
			case 'Alelo':
				return this.getBrandImagePath('alelo')

			case 'Amex':
				return this.getBrandImagePath('amex')

			case 'Cash':
				return this.getBrandImagePath('cash')

			case 'Diners':
				return this.getBrandImagePath('diners')

			case 'Elo':
				return this.getBrandImagePath('elo')

			case 'Hipercard':
				return this.getBrandImagePath('hipercard')

			case 'Master':
				return this.getBrandImagePath('master')

			case 'MasterCard':
				return this.getBrandImagePath('mastercard')

			case 'Sodexo':
				return this.getBrandImagePath('sodexo')

			case 'Visa':
				return this.getBrandImagePath('visa')

			case 'VR':
				return this.getBrandImagePath('vr')

			case 'Pix':
				return this.getBrandImagePath('pix-horizontal')

			default:
				return this.getBrandImagePath('default-card')
		}
	}

	getBrandImagePath(brand: string): string {
		return `../../../assets/payments/${brand}.svg`
	}

	// ---------------------------------- PAYMENT ---------------------------------- //

	removeProductUserFromTable() {
		this.productUserInfoStep = 'confirmRemove'
	}
}
