import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { AuthenticationService } from '../_services'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authenticationService: AuthenticationService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const currentUser = this.authenticationService.currentUserValue

		// if logged in, check if user's role is allowed
		if (currentUser) {
			const rolesRestriction = route.data.rolesRestriction

			// if no restriction role, return true
			if (!rolesRestriction || rolesRestriction?.length === 0) {
				return true
			}

			// else, check if user's role is allowed
			const isAllowed = rolesRestriction.find(role => role === currentUser.role)

			if (isAllowed) {
				return true
			}

			this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
			return false
		}

		// not logged in so redirect to login page with the return url
		this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
		return false
	}
}
