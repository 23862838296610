import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'

import { AuthenticationService } from '@app/_services'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private authenticationService: AuthenticationService,
		private toastr: ToastrService,
		protected http: HttpClient
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(err => {
				if (err.status === 401) {
					this.authenticationService.refreshToken()
				}

				const error = err.error?.message || err.message || err.statusText
				
				if(!err.error?.message){
					this.toastr.error(error)
				}

				return throwError(error)
			})
		)
	}
}
