import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { DisableControlDirective, InputRefDirective } from './_directives/'
import { InputComponent } from '@app/shared/_components'

@NgModule({
	imports: [CommonModule],
	declarations: [DisableControlDirective, InputRefDirective, InputComponent],
	exports: [DisableControlDirective, InputRefDirective, InputComponent],
})
export class InputModule {}
